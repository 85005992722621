import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Landing from "./components/main/Landing";
import Footer from "./components/main/Footer";
import styles from "./App.module.css";
import NavBar from "./components/main/NavBar";

function App() {
  return (
    <Router>
      <div className={` container ${styles.App}`}>
        <NavBar />
        <header className={styles.appHeader}>
          <Route exact path="/" component={Landing} />
        </header>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
