import React from "react";
import logo from "../../img/HawkinsGroup-logo-ref.png";
import styles from "./Landing.module.css";
//

function Landing() {
  return (
    <div className={` ${styles.Container}`}>
      <div className={` container ${styles.headerImg}`}>
        <div className={`row justify-content-center ${styles.headlineBox}`}>
          <div className={` col-md-  ${styles.headline}`}>
            <h3>Welcome to the Hawkins Group</h3>{" "}
            <p className="lead">
              <i> Helping Kittitas county reach its higest potential</i>
            </p>
          </div>
        </div>
       
      </div>
             {/***/}
             <div className="row justify-content-center">
        <div className="col-sm-12">
          <div className={`  ${styles.downArrow} ${styles.bounce}`}>
            <img
              width="60"
              height="60"
              alt=""
              src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSLQodC70L7QuV8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yODUsMTEuMjg0TDE2LDE5LjU3MWwtOC4yODUtOC4yODhjLTAuMzk1LTAuMzk1LTEuMDM0LTAuMzk1LTEuNDI5LDAgIGMtMC4zOTQsMC4zOTUtMC4zOTQsMS4wMzUsMCwxLjQzbDguOTk5LDkuMDAybDAsMGwwLDBjMC4zOTQsMC4zOTUsMS4wMzQsMC4zOTUsMS40MjgsMGw4Ljk5OS05LjAwMiAgYzAuMzk0LTAuMzk1LDAuMzk0LTEuMDM2LDAtMS40MzFDMjUuMzE5LDEwLjg4OSwyNC42NzksMTAuODg5LDI0LjI4NSwxMS4yODR6IiBmaWxsPSIjMTIxMzEzIiBpZD0iRXhwYW5kX01vcmUiLz48Zy8+PGcvPjxnLz48Zy8+PGcvPjxnLz48L3N2Zz4="
            />
          </div>
        </div>
      </div>
      {/** */}

      <br />
      <div className="container">
        <div className={`  row justify-content-center ${styles.darkoverlay}`}>
          <div className=" col-sm-8">
            <span>
              <i className="fas fa-road fa-2x"></i>
            </span>
            <p>
              Experts in road development. Difficult to access places, cutting,
              grading and surfacing.
            </p>
          </div>
          <div className="  col-sm-8">
            <span>
              <i className="fas fa-snowplow fa-2x"></i>
            </span>
            <p>New home site development</p>
          </div>
          <div className=" col-sm-8">
            <span>
              <i className="fas fa-hard-hat fa-2x"></i>
            </span>
            <p>Professional management</p>
          </div>
          <div className=" col-sm-8">
            <span>
              <i className="fas fa-tree fa-2x"></i>

              <p>Tree services extrodinare with over 45 years of experience.</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
