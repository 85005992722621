import React from "react";


export default () => {
  return (
    <footer className=" mb-2 p-2 text-white">
       <div className=" row justify-content-center">
        <span >
        <a
            href="mailto:hawkdj@yahoo.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <h3>
              {" "}
              <i className="fas fa-envelope-open-text " />
              Email
            </h3>
          </a>
        </span>
      </div>
      <div className='row justify-content-center'>
      <span className='text-center'>
        Copyright &copy; {new Date().getFullYear()} Hawkins Group, llc
      </span>
      </div>
     
    </footer>
  );
};
